import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./PriceList.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { t } from "../../locales/i18n";
import Header from "../../components/Header/Header";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import { Helmet } from "react-helmet";
import { images } from "../../assets/img";
import BookNowBtn from "../../components/BookNowBtn";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import CustomerFeedback from "../../components/CustomerFeedback/CustomerFeedback";

const PriceList = () => {
  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Price list"}</title>
        <meta name="service-price-list" content="Service price list" />
      </Helmet>
      <Header selected={"services"} />
      <div className={classes.wrapper}>
        <img src={images.pricing} alt={"pricing"} className={classes.pricing}/>
        <BookNowBtn />
      </div>
      <CustomerFeedback />

      <FooterInfo style={classes.footerStyle} />
      <FloatingButton />

    </div>
  );
};

export default PriceList;
