import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./AboutUs.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { t } from "../../locales/i18n";
import Header from "../../components/Header/Header";
import { Helmet } from "react-helmet";
import BookNowBtn from "../../components/BookNowBtn";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import { ReactPhotoCollage } from "react-photo-collage";
import { images } from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

const PHOTOS = [
  { source: images.nail01 },
  { source: images.nail02 },
  { source: images.nail03 },
  { source: images.nail04 },
  { source: images.nail05 },
  { source: images.nail06 },
  { source: images.nail07 },
  { source: images.nail08 },
  { source: images.nail09 },
  { source: images.nail10 },
  { source: images.nail11 },
  { source: images.nail12 },
];

const AboutUs = () => {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();
  const [collageLayout, setCollageLayout] = useState([]);
  const [collageHeight, setCollageHeight] = useState([]);

  useEffect(() => {
    const { layout, height } = generateLayoutAndHeight(PHOTOS.length) || {};
    setCollageLayout(layout);
    setCollageHeight(height);
  }, []);

  function generateLayoutAndHeight(imageCount) {
    const mobileHeightRange = { min: 23, max: 36 };
    const otherHeightRange = { min: 30, max: 47 };

    // Function to generate random numbers between a range
    const getRandomInRange = (min, max) =>
      Math.floor(Math.random() * (max - min + 1)) + min;

    // Function to generate random layout arrays
    const getRandomLayout = (isMobile) => {
      const maxItems = isMobile ? 3 : 4; // max 3 for mobile, 4 for others
      const layout = [];
      let totalImages = 0;

      while (totalImages < imageCount) {
        const numItems = getRandomInRange(1, maxItems);
        layout.push(numItems);
        totalImages += numItems;
      }
      return layout;
    };

    // Get randomized layout array
    const layout = getRandomLayout(isMobile);

    // Generate matching height array based on the layout
    const height = layout.map(() => {
      return isMobile
        ? `${getRandomInRange(mobileHeightRange.min, mobileHeightRange.max)}rem`
        : `${getRandomInRange(otherHeightRange.min, otherHeightRange.max)}rem`;
    });

    return { layout, height };
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"About us"}</title>
        <meta name="about-us" content="About us" />
      </Helmet>
      <Header selected={"about-us"} />

      <h1>{"\n"}</h1>

      {collageLayout && collageLayout.length > 0 && (
        <ReactPhotoCollage
          width={"100%"}
          layout={collageLayout}
          height={collageHeight}
          photos={PHOTOS}
          showNumOfRemainingPhotos
        />
      )}
      <BookNowBtn style={classes.bookNowBtnStyle} />
      <FooterInfo style={classes.footerStyle} />
      <FloatingButton />
    </div>
  );
};

export default AboutUs;
