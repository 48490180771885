import classes from "./Introduction.module.css";
import React, { useEffect, useContext, useState } from "react";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import MainCarousel from "../Carousel/Carousel";
import InstagramFeed from "../InstagramFeed/InstagramFeed";

const Introduction = (props) => {
  const { introductionRef } = props || {};
  const isMobile = useCheckIsMobile();

  return (
    <div ref={introductionRef} className={classes.container}>
      <div className={classes.introduction}>
        <div className={classes.textContainer}>
          <h1>{`Elevate Your Beauty, Pamper Your Senses\n`}</h1>
          <h4>
            At TK Nails, we blend beauty and relaxation with expert care. Our
            salon offers premium manicures, pedicures, and nail enhancements in
            a serene, luxurious setting. Using top-quality products, we ensure
            your nails not only look stunning but remain healthy and strong.
            Visit TK Nails for a personalized, rejuvenating experience where
            beauty meets craftsmanship.
          </h4>
          <BookNowBtn />
        </div>

        <div
          style={{
            borderBottomLeftRadius: "100%",
            borderBottomRightRadius: "100%",
          }}
        >
          <img
            className={classes.imageStyle}
            alt={"Introduction"}
            src={images.banner02}
          />
        </div>
      </div>
      <InstagramFeed />

      <div className={classes.contactStaffContainer}>
        <div className={classes.wrapperStyling}>
          <h1>Talk to our staff</h1>
          <h4 style={{marginTop:'2vh',marginBottom: '4vh'}}>
            Our friendly and knowledgeable team at TK Nails is here to assist
            you with any questions or recommendations. Whether you’re looking
            for advice on the perfect nail treatment or need help choosing a
            color, our staff is always ready to provide personalized care and
            ensure your experience is exceptional.
          </h4>
          <BookNowBtn style={classes.bookNowBtnStyle}/>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
