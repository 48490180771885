import classes from "./PrimaryBanner.module.css";
import React, { useContext, useEffect, useState, memo } from "react";
import AuthContext from "../../store/auth-context";
import { t } from "../../locales/i18n";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import { useNavigate } from "react-router-dom";

const PrimaryBanner = (props) => {
  const {} = props || {};
  const navigate = useNavigate();

  return (
    <div
      className={classes.primaryBanner}
      style={{ backgroundImage: images.banner01 }}
    >
      <img
        className={`${classes.mainLogo}`}
        alt={"main logo"}
        src={images.main_logo}
      />

      <div
      
        className={classes.centeredContainer}
      >
        <h1>{`Premier Nail Care in Camberley`}</h1>
        <h2 style={{ marginBottom: "7vh", marginTop: "3vh" }}>
          {`Exceptional nail services at Camberley.`}
        </h2>

        <BookNowBtn />
      </div>

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flexDirection: "row" }}>
          <a target={"_blank"} href={NAIL_SHOP_INFO.instagram} rel="noreferrer">
            <img
              className={classes.socialIcon}
              alt={"insta"}
              src={images.instaIcon}
            />
          </a>
          <a target={"_blank"} href={NAIL_SHOP_INFO.facebook} rel="noreferrer">
            <img
              className={classes.socialIcon}
              alt={"insta"}
              src={images.fbIcon}
            />
          </a>
        </div>

        <div
          onClick={() => navigate("about-us")}
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent:'center',
            cursor: "pointer",
          }}
        >
          <h3 style={{ textDecoration: "underline", color: "#fff" }}>
            See our collections
          </h3>
          <img
            className={classes.rightArrow}
            alt={"right-arrow"}
            src={images.leftArrow}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(PrimaryBanner);
