import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import Helpers from "../../utils/Helpers";
import classes from "./InstagramFeed.module.css";
import { images } from "../../assets/img";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";
import "../../index.css";

const ACCESS_TOKEN =
  "IGQWRPajJLeFNXcWs2R3NjV1RnZAnRUZAjhGRlV5WW1PS2dlYUgxc2NVOTlXcHI3TVBzSUNlN2xoZA0FNMmZAyQXdKNEVTQ3doc2VkeDZA1QTJFVlBzclh4Qkg1S3RhVHNEVWlPd3lwRFRXYzk5NmI4T2RKN1ctLVUwRnMZD";
const USER_ID = "24125941530353577";
const MAXIMUM_IMAGES_FETCHED = 8;

const InstagramFeed = () => {
  const isMobile = useCheckIsMobile();
  const NUMBER_ITEMS_PER_ROW = isMobile ? 2 : 3;
  const [instagramFeed, setInstagramFeed] = useState([]);
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);

  useEffect(() => {
    const fetchedConfigImg = RemoteConfig.getConfigValue("instagram_images");
    let instaImg = [];

    for (let i = 0; i < fetchedConfigImg.length; i++) {
      const stringValue = fetchedConfigImg[i];
      const resultObject = { mediaUrl: stringValue };
      instaImg.push(resultObject);
    }

    if (instaImg && Array.isArray(instaImg) && instaImg.length > 0) {
      setInstagramFeed(splitArrayIntoGroup(instaImg));
    } else {
      setInstagramFeed(
        splitArrayIntoGroup([
          { mediaUrl: images.nail01 },
          { mediaUrl: images.nail02 },
          { mediaUrl: images.nail03 },
          { mediaUrl: images.nail04 },
          { mediaUrl: images.nail05 },
          { mediaUrl: images.nail06 },
        ])
      );
    }
  }, [isFetchActivateRemoteConfig, isMobile]);


  useEffect(() => {
    const fetchMedia = async (id) => {
      const mediaUrl = `https://graph.instagram.com/${id}?access_token=${ACCESS_TOKEN}&fields=media_url,permalink`;
      const res = await fetch(mediaUrl);
      const json = await res?.json();
      return { permalink: json?.permalink, mediaUrl: json?.media_url };
    };

    const doFetch = async () => {
      const res = await fetch(
        `https://graph.instagram.com/${USER_ID}/media?access_token=${ACCESS_TOKEN}`
      );
      const json = (await res?.json())?.data;

      const fetchedItems = [];
      if (json?.length > 0) {
        for (
          let i = 0;
          i < json?.length && fetchedItems.length < MAXIMUM_IMAGES_FETCHED;
          i++
        ) {
          const item = json[i];
          const itemId = item.id;
          const instaItem = await fetchMedia(itemId);

          if (!instaItem.mediaUrl.includes("video")) {
            fetchedItems.push(instaItem);
          }
        }

        //Split array into group of NUMBER_ITEMS_PER_ROW
        const grouped = splitArrayIntoGroup(fetchedItems);

        setInstagramFeed(grouped);
      }
    };

    // NOTE: PREVENT FETCHING ON DEV, REMOVE CONDITION TO SEE THE LOGIC RUN
    //TODO: UNCOMMENT THESE 3 LINES TO FETCH IMG FROM INSTAGRAM ELSE USE LOCAL IMG
    // if (!Helpers.isDev) {
    //   doFetch();
    // }
  }, [NUMBER_ITEMS_PER_ROW]);

  const splitArrayIntoGroup = (imgArray) => {
    return imgArray.reduce((acc, post, ind) => {
      const index = parseInt(ind / NUMBER_ITEMS_PER_ROW);
      acc[index] = acc[index] || [];
      acc[index].push(post);
      return acc;
    }, {});
  };

  if (!instagramFeed) {
    return null;
  }

  const openInstagram = () => {
    window.open(NAIL_SHOP_INFO.instagram, "_blank");
  };

  return (
    <div className={classes.container}>
      <div className={classes.instaIconContainer} onClick={openInstagram}>
        <img
          src={images.instaIcon}
          alt={"instagram-icon"}
          className={classes.instaIcon}
        />
        <h3>{NAIL_SHOP_INFO.instagramName}</h3>
      </div>

      {!!instagramFeed &&
        Object.keys(instagramFeed).map((row, index) => {
          return (
            <div
              key={index}
              className={`${classes.feedContainer} ${
                index === (isMobile ? 0 : 1) ? classes.noMargin : ""
              }`}
            >
              {Helpers.isArrayAvailable(instagramFeed[row])
                ? instagramFeed[row].map((item, index2) => {
                    return (
                      <img
                        key={`${index2}_${item?.mediaUrl?.toString()}`}
                        onClick={() =>
                          window.open(NAIL_SHOP_INFO.instagram, "_blank")
                        }
                        className={`${classes.feedImage}`}
                        alt={item?.mediaUrl?.toString()}
                        src={item?.mediaUrl}
                      />
                    );
                  })
                : null}
            </div>
          );
        })}

      <div
        onClick={openInstagram}
        className={`${classes.followMeContainer} linear-gradient-bg`}
      >
        <img
          alt={"instagram-icon"}
          src={images.instaIcon}
          className={`${classes.instaIconSmall}`}
        />
        <h4>Follow us on Instagram</h4>
      </div>
    </div>
  );
};

export default memo(InstagramFeed);
