import { images } from "../assets/img";

export const NAIL_SHOP_INFO = {
  ggMapUrl: "https://maps.app.goo.gl/ik74jDPfVidBYr6k8",
  address: "Unit 1 Mustafa House, 1 Portesbery Road. Camberley GU15 3TA",
  ggMapIframeUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9971.328860604917!2d-0.7676798137690143!3d51.332605855144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875d5619677b9f7%3A0xc9e5629732090688!2sTK%20Nails!5e0!3m2!1sen!2s!4v1729684456670!5m2!1sen!2s",
  email: "tk.nails2024@gmail.com",
  tel: "+4473019418889",
  phone: "+44 730 194 1888",
  facebookId:"61565143170902",
  instagramName: "tknails.24",
  name: "TK Nails",
  bookingUrl: "https://tknailscamberley.setmore.com",
  openingTime:{
    openTime:{weekdays: "MONDAY - SATURDAY", time:"09:30 - 18:30"},
    closeTime:{weekdays: "SUNDAY", time:"Closed"}
  },
};
NAIL_SHOP_INFO.instagram = `https://www.instagram.com/${NAIL_SHOP_INFO.instagramName}`;
NAIL_SHOP_INFO.instagramChatUrl = `https://ig.me/m/${NAIL_SHOP_INFO.instagramName}`;
NAIL_SHOP_INFO.facebook = `https://www.facebook.com/profile.php?id=${NAIL_SHOP_INFO.facebookId}`
NAIL_SHOP_INFO.facebookMessengerUrl = `http://m.me/${NAIL_SHOP_INFO.facebookId}`

export const SOLCO_INFO = {
  facebook: "https://www.facebook.com/solcoagency.marketing",
};

export const LANGUAGE_ID = {
  vi: "vi",
  en: "en",
};

export const CUSTOMER_FEEDBACK = [
  {
    name: "Loren Stutts",
    feedback:
      "“Love the team here, particularly once they were acquired by new management about 2-ish years ago. My nails are always exactly what I ask for, they have an expansive colour selection, comfy chairs for pedicures - the vibey music is always a nice bonus. Would suggest to book in advance though as they’re good, so are usually busy!”",
    avatar: "https://i.imgur.com/sU1i6Sk.png",
  },
  {
    name: "shazia lynch",
    feedback:
      "“I really enjoyed my experience. I got a €35 Groupon deal and came out with pretty nails. Thank you. Such a lovely team, clean place 💗💗💗💗”",
    avatar: "https://i.imgur.com/Lq7YQ1o.png",
  },
  {
    name: "Miriam Deas",
    feedback:
      "“Really lovely friendly staff and one of the best manicures I’ve had. Attention to detail and good advise too for next time. Highly recommend!”",
    avatar: "https://i.imgur.com/HDBW1Xg.png",
  },
  {
    name: "Laura Geere",
    feedback:
      "“Love this place been coming here for over a year now. never had a bad set all the staff is wonderful and friendly. Every time me and my best mate come in they are fabulous”",
    avatar: "https://i.imgur.com/RsFFmiS.png",
  },
  {
    name: "Cocokaitlyn",
    feedback:
      "“Got my nice nail color. Would definitely recommend this salon to every girls out there.Really pleased to have this new salon in the local area. Clean, spacious salon, good value and a nice manicure that has lasted well. A great experience which I definetely would recommend. The staff is welcoming.I will be going back.”",
    avatar: "https://i.imgur.com/Ds2X7zK.png",
  },
  {
    name: "Isabella White",
    feedback:
      "“I booked a last-minute appointment, and they exceeded my expectations. My gel manicure has been flawless for weeks!”",
    avatar: "https://i.imgur.com/KxhUFKM.jpg",
  },
  {
    name: "Scarlett Brown",
    feedback:
      "“Very professional service. My nails always look stunning and they cater to any design you like. The staff are all super friendly and I wouldn't go to any other salon ❤️. Make sure you book an appointment as they are usually quite busy!”",
    avatar: "https://i.imgur.com/kI3rsKc.jpg",
  },
  {
    name: "Ella Wilson",
    feedback:
      "“Really great quality work! I’ve been to much more expensive places in central London, but they do a much better job here 💜 they take care, have great attention to detail, and they let me check that I like the shape and am happy with how things look at every stage which I really appreciate as I’m a bit of a perfectionist! Highly recommend Trang 💜💜”",
    avatar: "https://i.imgur.com/92gXkP8.jpg",
  },
];

export const MENU_HEADER = [
  { name: "Home", navigationKey: "home" },
  { name: "About Us", navigationKey: "about-us" },
  { name: "Services", navigationKey: "services" },
  { name: "Booking", navigationKey: NAIL_SHOP_INFO.bookingUrl },
  // { name: "Contacts", navigationKey: "contactsRef" },
  { name: "Contact", navigationKey: "contact-us" },
  { name: "Policy", navigationKey: "policy" },
];
